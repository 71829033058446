<template>
  <div class="page-not-found">
    <div class="wrapper">
      <div class="page-not-found__wrapper">
        <div class="page-not-found__header">
          <h2 class="page-not-found__title title">Произошла ошибка</h2>
        </div>
        <div class="page-not-found__body">
          <div class="page-not-found__block">
            <h6 class="page-not-found__subtitle">
              <div class="vertical-line"></div>
              Страница не найдена
            </h6>
            <p>
              Возможно она больше не существует или произошла другая ошибка.
            </p>
          </div>
        </div>
        <div class="page-not-found__footer">
          <router-link :to="{ path: '/' }" class="page-not-found__button">
            <div class="button__label">На главную</div>
            <div class="button__circle">
              <img
                src="../assets/icons/arrow-next.svg"
                class="arrow"
                alt="Next arrow"
                loading="lazy"
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-not-found {
  padding: 110px 10px 50px 10px;
  width: 100%;
  @include for-tablet-portrait-up {
    padding: 132px 15px 60px 15px;
  }
  @include for-tablet-landscape-up {
    padding: 142px 16px 70px 16px;
  }
  @include for-desktop-up {
    padding: 132px 0 106px 0;
  }
  &__button {
    margin-top: 40px;
    @include for-tablet-portrait-up {
      margin-top: 50px;
    }
    @include for-tablet-landscape-up {
      margin-top: 70px;
    }
    @include for-desktop-up {
      margin-top: 80px;
    }
    @include not-found__btn();
    .button__circle {
      @include form-btn__circle();
      background: var(--white-color);
    }
    .button__label {
      display: flex;
      width: calc(100% - 32px);
      justify-content: center;
      text-align: center;
      text-decoration: none;
      @include for-tablet-portrait-up {
        width: calc(100% - 36px);
      }
      @include for-tablet-landscape-up {
        width: calc(100% - 40px);
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__title {
    @include H4-semi-bold();
    padding-bottom: 40px;
    color: var(--dark-blue-color);
    @include for-tablet-landscape-up {
      @include H2-semi-bold();
    }
    @include for-desktop-up {
      @include H1-semi-bold();
      padding-bottom: 60px;
    }
  }
  &__subtitle {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include P1-bold();
    @include for-tablet-portrait-up {
      @include P1-bold();
      margin-bottom: 16px;
    }
    @include for-tablet-landscape-up {
      @include H5-bold();
      margin-bottom: 11px;
    }
    @include for-desktop-up {
      @include H5-bold();
    }
    .vertical-line {
      background: var(--dark-gradient-background);
      width: 4px;
      height: 30px;
      border-radius: 8px;
      margin-right: 8px;
      @include for-tablet-portrait-up {
        height: 34px;
        margin-right: 10px;
      }
      @include for-tablet-landscape-up {
        height: 42px;
      }
    }
  }
  &__block + &__block {
    margin-top: 30px;
  }
  &__block {
    p {
      @include P4();
      @include for-tablet-landscape-up {
        @include P3();
      }
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
    }
  }
}
</style>
